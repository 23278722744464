import React, { useState } from "react";

function About(props) {
  window.addEventListener("mousemove", (e) => {
    let xAxis = (window.innerWidth / 2 - e.pageX) / 60;
    let yAxis = (window.innerWidth / 2 - e.pageY) / 60;
    document.getElementById(
      "looked"
    ).style.transform = `rotateX(${yAxis}deg) rotateY(${xAxis}deg)`;
    document.getElementById("look").style.transform = `rotateX(${
      yAxis / 3
    }deg) rotateY(${xAxis / 3}deg) translateZ(500px)`;
  });
  document.addEventListener("scroll", (e) => {
    console.log("helol");
  });

  const [program, setProgram] = useState([
    {
      what: "HTML",
      how: "95%",
    },
    {
      what: "CSS",
      how: "97%",
    },
    {
      what: "SASS",
      how: "96%",
    },
    {
      what: "Bootstrap",
      how: "92%",
    },
    {
      what: "Styled-components",
      how: "94%",
    },
    {
      what: "Tailwind CSS",
      how: "96%",
    },
    {
      what: "Material ui",
      how: "96%",
    },
    {
      what: "Java Script",
      how: "92%",
    },
    {
      what: "TypeScript",
      how: "92%",
    },
    {
      what: "React Js",
      how: "93%",
    },
    {
      what: "Next Js",
      how: "93%",
    },
    {
      what: "Reactstrap",
      how: "96%",
    },
    {
      what: "Redux",
      how: "94%",
    },
    {
      what: "Axios",
      how: "94%",
    },
    {
      what: "Redux Toolkit",
      how: "95%",
    },
    {
      what: "Git",
      how: "90%",
    },
  ]);

  return (
    <div
      data-aos="fade-up"
      data-aos-anchor-placement="top-bottom"
      className="About"
    >
      <div className="name">
        MAJIDOV <br /> DONIYOR <br /> BOZOR O'G'LI
      </div>
      <div id="looked" className="content">
        <div id="look" className="content-hov">
          <b className="gradient-all-2">" THIS IS MY FAVORITE THING TO DO "</b>
        </div>
      </div>
      <div className="row my-5">
        {program.map((item, index) => (
          <div key={index} className="program my-2 col-md-10 me-md-3">
            <div className="nameP">{item.what}</div>
            <div className="numberP">
              <div className="box">
                <div style={{ width: item.how }}>{item.what}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default About;
