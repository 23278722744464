import React from 'react';
import './Wait.css'

function Wait(props) {
    return (
        <div>
            <section>
                <div className="loader">
                    <span className="i-1">doniyor</span>
                    <span className="i-2">doniyor</span>
                    <span className="i-3">doniyor</span>
                    <span className="i-4">doniyor</span>
                    <span className="i-5">doniyor</span>
                    <span className="i-6">doniyor</span>
                    <span className="i-7">doniyor</span>
                    <span className="i-8">doniyor</span>
                    <span className="i-9">doniyor</span>
                    <span className="i-10">doniyor</span>
                    <span className="i-11">doniyor</span>
                    <span className="i-12">doniyor</span>
                    <span className="i-13">doniyor</span>
                    <span className="i-14">doniyor</span>
                    <span className="i-15">doniyor</span>
                    <span className="i-16">doniyor</span>
                    <span className="i-17">doniyor</span>
                    <span className="i-18">doniyor</span>
                    <span className="i-19">doniyor</span>
                    <span className="i-20">doniyor</span>
                </div>
            </section>
        </div>
    );
}

export default Wait;