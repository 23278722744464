import React, { useState } from "react";
import { Route, Link } from "react-router-dom";
import About from "./About";
import Portfolio from "./Portfolio";
import Contact from "./Contact";
import { SiSpring } from "react-icons/si";
import { RiMoonClearFill } from "react-icons/ri";
import { BsSun } from "react-icons/bs";

function MainHeader(props) {
  const [col, setCol] = useState(false);
  const [moon, setMoon] = useState(true);

  function img() {
    setCol(false);
    document.getElementById("piano").pause();
  }

  function ok() {
    setCol(true);
    document.getElementById("piano").play();
  }

  function moonSun() {
    setMoon(!moon);
  }

  return (
    <>
      <audio id="piano" src="Piano.mp3"></audio>
      <button className={col ? "open-but" : "d-none"} onClick={img}>
        <SiSpring />
      </button>
      <div className={`${col ? "d-none" : "open-but-2"}`} onClick={moonSun}>
        {moon ? <RiMoonClearFill /> : <BsSun />}
      </div>
      <div
        className={`MainHeader ${
          col ? "MainHeader-color-2" : "MainHeader-color"
        }`}
      >
        <div className={`${col ? "d-none" : moon ? "stage" : "stage stage2"}`}>
          <div className={moon ? "sun" : "moon"}></div>
          <div className="grass"></div>
          <div className="square"></div>
          <div className="round"></div>
        </div>
        <img onClick={img} src="don.jpg" alt="" className="img-me" />
        <Link to="/ABOUT">
          <button onClick={ok} className="text-me">
            ABOUT ME
          </button>
        </Link>
        <Link to="/PORTFOLIO">
          <button onClick={ok} className="text-me">
            PORTFOLIO
          </button>
        </Link>
        <Link to="/CONTACT">
          <button onClick={ok} className="text-me">
            CONTACT
          </button>
        </Link>
      </div>
      <div className={`right-site ${col ? "right-site-2" : "right-site-1"}`}>
        <Route path="/about" render={() => <About />} />
        <Route path="/portfolio" render={() => <Portfolio />} />
        <Route path="/contact" render={() => <Contact />} />
      </div>
    </>
  );
}

export default MainHeader;
