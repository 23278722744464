import React from "react";
import { ImTelegram, ImGithub } from "react-icons/im";
import { FaInstagram, FaFacebookSquare } from "react-icons/fa";

function Contact(props) {
  return (
    <div
      data-aos="fade-up"
      data-aos-anchor-placement="top-bottom"
      className="Contact"
    >
      <div className="tel">
        <div className="animate-motion">
          <div id="looked" className="border border-dark w-50 border-4 mx-auto">
            <div id="look">TEL:</div>
          </div>
        </div>
        <div className="animate-motion">+998 90 013 24 00</div>
        <div className="animate-motion">+998 93 592 10 12</div>
      </div>
      <div className="network">
        <a
          className="animate-gradient"
          target="_blank"
          href="https://t.me/M_DONIY"
        >
          <ImTelegram />
        </a>
        <a
          className="animate-gradient"
          target="_blank"
          href="https://www.instagram.com/_m_doniy_"
        >
          <FaInstagram />
        </a>
        <a
          className="animate-gradient"
          target="_blank"
          href="https://www.facebook.com/doniyor.majidov.12"
        >
          <FaFacebookSquare />
        </a>
        <a
          className="animate-gradient"
          target="_blank"
          href="https://github.com/MajidovDoniyor"
        >
          <ImGithub />
        </a>
      </div>
    </div>
  );
}

export default Contact;
