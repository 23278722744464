import React, { useState } from "react";

function Portfolio(props) {
  const [work, setWork] = useState([
    {
      img: "truc.jpg",
      ish: "HTML,CSS , JS, Next js",
      rout: "https://trucking-times.com/",
    },
    {
      img: "car.jpg",
      ish: "HTML,CSS , JS, React js",
      rout: "https://www.cardiocenter.uz/",
    },
    {
      img: "sin.jpg",
      ish: "HTML,CSS , JS, REACT js",
      rout: "https://www.synergy-university.uz/",
    },
    {
      img: "setup.jpg",
      ish: "HTML,CSS , JS, React js",
      rout: "https://www.setupit.uz/",
    },
    {
      img: "yosh.jpg",
      ish: "HTML,CSS , JS, React js",
      rout: "https://yoshoila.uz/",
    },
    {
      img: "tdtu.jpg",
      ish: "HTML,CSS , JS, React js",
      rout: "https://tdtu.uz/",
    },
    {
      img: "ela.jpg",
      ish: "HTML,CSS , JS, React js",
      rout: "https://elacademy.uz/",
    },
    {
      img: "uzswlu.jpg",
      ish: "HTML,CSS , JS, React js ,Test",
      rout: "https://uzswlu.vercel.app/",
    },
    {
      img: "mag.jpg",
      ish: "HTML,CSS , JS, Next js",
      rout: "https://magazen-gules.vercel.app/",
    },
    {
      img: "bog.jpg",
      ish: "HTML,CSS , JS, React js, Test",
      rout: "https://bogcha-uz.vercel.app/",
    },
    {
      img: "urik.jpg",
      ish: "HTML,CSS , JS, React Js",
      rout: "https://urikzorshaxrisabz.netlify.app",
    },
  ]);

  return (
    <div
      data-aos="fade-up"
      data-aos-anchor-placement="top-bottom"
      className="Portfolio"
    >
      <div id="looked">
        <div id="look" className="name">
          My Works
        </div>
      </div>

      {work.map((item, index) => (
        <a href={item.rout} target="_blank" key={index} className="box mb-2">
          <img src={item.img} alt="" />
          <div className="text">{item.ish}</div>
        </a>
      ))}
    </div>
  );
}

export default Portfolio;
