import React, { useState } from "react";
import MainHeader from "./component/MainHeader";
import { BrowserRouter } from "react-router-dom";
import Wait from "./Wait";

function App(props) {
  document.addEventListener("mousemove", (e) => {
    document.getElementById("myBtn").style.top = `${e.pageY}px`;
    document.getElementById("myBtn").style.left = `${e.pageX}px`;
    document.getElementById("myBtn2").style.top = `${e.pageY - 12}px`;
    document.getElementById("myBtn2").style.left = `${e.pageX - 12}px`;
  });

  const [isLoading, setIsLoading] = useState(true);
  // useEffect(()=>{
  //     window.addEventListener('load', ()=>{
  //         setIsLoading(false)
  //     });
  // }, []);

  setTimeout(() => {
    setIsLoading(false);
  }, 1500);

  return (
    <BrowserRouter>
      <div id="myBtn" className="cursor"></div>
      <div id="myBtn2" className="cursor-follower">
        MD
      </div>
      {isLoading === true ? <Wait /> : <MainHeader />}
    </BrowserRouter>
  );
}

export default App;
