import React from "react";
import ReactDom from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./global.scss";
import App from "./App";
import "aos/dist/aos.css";
import "aos/dist/aos";
import AOS from "aos";

class Index extends React.Component {
  componentDidMount() {
    AOS.init({
      duration: 3000,
    });
  }
  render() {
    return (
      <>
        <App />
      </>
    );
  }
}

ReactDom.render(<Index />, document.getElementById("root"));
